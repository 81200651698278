<template>
    <span>
        <input class="absolute" style="left: -99999px"
            type="file"
            id="files"
            name="files"
            :multiple="multiple"
            ref="files"
            @change="handleVaporUpload()"
            :disabled="disabled"
            :accept="accept"
        />
        <button type="button"
            class="flex justify-center items-center w-full py-3 bg-brandYellow text-white rounded cursor-pointer"
            :class="{'opacity-50 cursor-not-allowed': disabled}"
            @click="addFiles()"
            v-if="!uploading">
            <slot name="label">Upload</slot>
        </button>
        <button type="button" class="flex justify-center items-center w-full py-3 bg-brandYellow text-white rounded cursor-pointer opacity-50 cursor-not-allowed" v-if="uploading">
            Uploading, please wait...
        </button>
    </span>
</template>

<script>
import axios from 'axios'

export default {
    name: 'FileUpload',
    props: {
        folder: { default: 'uploads' },
        multiple: { default: true },
        delay: { default: 0 },
        locked: { default: false },
        accept: { default: '*' },
        model: { default: null },
        id: { default: null },
        group: { default: null },
        selectedFolder: { default: null }
    },
    data: function () {
        return {
            files: [],
            uploading: false,
            percentCompleted: 0,
            disabled: false
        }
    },
    mounted () {
        if (this.locked) {
            this.disabled = true
        }
    },
    methods: {
        addFiles () {
            this.$refs.files.click()
        },
        handleVaporUpload: async function () {
            this.$emit('uploading')
            this.disabled = true
            this.uploading = true

            const uploadedFiles = this.$refs.files.files

            for (let i = 0; i < uploadedFiles.length; i++) {
                await this.store(uploadedFiles[i], {
                    progress: progress => {
                        this.uploadProgress = Math.round(progress * 100)
                    }
                }).then(response => {
                    const fileData = {
                        filename: uploadedFiles[i].name,
                        type: uploadedFiles[i].type,
                        size: uploadedFiles[i].size,
                        key: response.key,
                        extension: response.extension,
                        model: this.model,
                        id: this.id,
                        group: this.group,
                        selectedFolder: this.selectedFolder,
                        folder: this.folder
                    }

                    this.files.push(fileData)
                })
            }

            await axios.post(process.env.VUE_APP_API_URL + '/vapor/upload', { files: this.files }).then(response => {
                setTimeout(() => {
                    this.resetUpload()

                    if (response.data.success === true) {
                        this.$emit('uploaded', response.data)
                    } else {
                        this.$emit('error', response.data.message)
                    }

                    this.disabled = false
                    this.uploading = false
                }, parseInt(this.delay))
            }).catch(() => {
                this.resetUpload()
            })
        },
        store: async function (file, options = null) {
            const response = await axios.post(process.env.VUE_APP_API_URL + '/vapor/signed-storage-url', {
                bucket: options.bucket || '',
                content_type: options.contentType || file.type,
                expires: options.expires || ''
            })

            if (typeof options.progress === 'undefined') {
                options.progress = () => {}
            }
            // This is the fix for the headers. Instance just for the S3 PUT
            const instance = axios.create()
            instance.defaults.headers.common = {}

            await instance.put(response.data.url, file, {
                headers: response.data.headers,
                onUploadProgress: (progressEvent) => {
                    options.progress(progressEvent.loaded / progressEvent.total)
                }
            })

            response.data.extension = file.name.split('.').pop()

            return response.data
        },
        resetUpload: function () {
            this.uploading = false
            this.percentCompleted = 0
            this.files = []
            this.disabled = false
        }
    },
    watch: {
        locked: function (newVal) {
            this.disabled = newVal
        }
    }
}
</script>

<style scoped>

</style>
