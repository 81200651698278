<template>
    <Header :show-details="true"></Header>

    <!-- Progress Bar -->
    <div class="flex justify-between items-center px-3 mt-5">
        <div class="text-lg text-gray-600">STEP {{ step }}</div>
        <div class="w-3/4 flex items-center border-2 border-border rounded-xl" style="padding: 2px;">
            <div :class="['w-' + step + '/3 h-6 rounded-xl', { 'w-full': step === 3 }]" style="background-color: #23b0cb;"></div>
        </div>
    </div>

    <div class="flex w-full items-center justify-center py-6" v-if="loading">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="spinner" class="svg-inline--fa fa-spinner fa-w-16 fa-spin w-16 h-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z"></path></svg>
    </div>

    <!-- Application Type -->
    <div class="mx-3 rounded-lg flex justify-between px-3 py-4 mt-5 text-white text-lg" v-if="!loading" :style="'background-color: '+ form.background_colour +';'">
        <div>
            <h6>{{ form.name }}</h6>
            <template v-if="step !== 3">
                <h6 class="mt-4">Limit ${{ form.limit.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</h6>
                <h6 class="mt-4">Remaining ${{ form.values.remaining.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</h6>
            </template>
            <template v-if="step === 3 && submission.id">
                <h6 class="mt-4">Status: {{ submission.application_status.name }}</h6>
                <h6 class="mt-4" v-if="submission.lodged_at">Application Submitted: {{ dayjs(submission.lodged_at).format('dddd MMMM D YYYY') }}</h6>
                <h6 class="mt-4" v-else>Application Submitted: N/A</h6>
            </template>
        </div>
        <div class="w-1/6">
            <img :src="s3URL + '/' + form.icon_url" alt="">
            <div id="healthcareFaqOpen" class="underline text-center mt-2 cursor-pointer" v-if="step !== 3 && form.faqs && form.faqs.length > 0" @click="showFAQs = true">FAQ's</div>
        </div>
    </div>

    <!-- HealthCare FAQ -->
    <div class="px-3 transition-all duration-300 ease-in-out" v-if="showFAQs">
        <div class="flex justify-between items-center pt-4">
            <div class="font-bold">FAQ's</div>
            <div id="healthcareFaqClose" class="w-32 p-3 bg-secondary text-center text-white font-bold rounded-sm cursor-pointer" @click="showFAQs = false">CLOSE</div>
        </div>
        <!-- Questions -->
        <div class="pb-4">
            <div class="mt-4" v-for="(faq, FAQIndex) in form.faqs" :key="FAQIndex">
                <div class="font-bold text-text2">Q: {{ faq.question }}</div>
                <div class="text-text2 mt-1">A: {{ faq.answer }}</div>
            </div>
        </div>
    </div>

    <div v-if="!loading && step === 1">
        <!-- Applicant & Profile-->
        <div class="mt-4 px-3">
<!--            <div class="block font-bold text-text text-xl">Applicant and Profile</div>-->
<!--            <form action="" class="mt-4 text-lg" v-if="store.getters.user.relationships && store.getters.user.relationships.length > 0">-->
<!--                <select id="applicant" name="applicant" v-model="applicant" @change="changeMember" class="w-full py-3 px-2 border border-border rounded focus:outline-none cursor-pointer">-->
<!--                    <option :value="store.getters.user.id" class="cursor-pointer">{{ store.getters.user.first + ' ' + store.getters.user.last }}</option>-->
<!--                    <option v-for="(applicant, index) in store.getters.user.relationships" :key="index" :value="applicant.id" class="cursor-pointer">{{ applicant.first + ' ' + applicant.last }}</option>-->
<!--                </select>-->
<!--            </form>-->
<!--            <span v-else>{{ store.getters.user.first + ' ' + store.getters.user.last }}</span>-->

            <div class="mt-5 pt-4 mb-6 text-gray-600 text-lg border-t border-text2">Please confirm your details below are correct</div>

            <!-- Details -->
            <div class="mt-3 text-lg">
                <span class="text-text font-bold">Name: </span>
                <span class="text-text2">{{ member.first + ' ' + member.last }}</span>
            </div>
            <div class="mt-3 text-lg">
                <span class="text-text font-bold">Mobile: </span>
                <span class="text-text2">{{ member.mobile }}</span>
            </div>
            <div class="mt-3 text-lg">
                <span class="text-text font-bold">Email: </span>
                <span class="text-text2">{{ member.email }}</span>
            </div>
            <!-- Address -->
            <div>
                <div class="mt-3 text-lg">
                    <span class="text-text font-bold">Address:</span>
                </div>
                <div class="text-lg text-text2" v-if="member.address">{{ member.address }}</div>
                <div class="text-lg text-text2" v-if="member.suburb">{{ member.suburb }}</div>
                <div class="text-lg text-text2" v-if="member.state || member.postcode">{{ member.state }}, {{ member.postcode }}</div>
                <div class="text-lg text-text2" v-if="!member.address && !member.suburb && !member.state && !member.postcode">N/A</div>
            </div>
            <!-- Bank Details -->
            <div>
                <div class="mt-3 text-lg text-text font-bold">
                    Bank Details:
                </div>
                <div class="text-lg text-text2" v-if="member.account_name">Account Name: {{ member.account_name }}</div>
                <div class="text-lg text-text2" v-if="member.account_bsb">BSB: {{ member.account_bsb }}</div>
                <div class="text-lg text-text2" v-if="member.account_number">Account Number: {{ member.account_number }}</div>
                <div class="text-lg text-text2" v-if="!member.account_name && !member.account_bsb && !member.account_number">N/A</div>
            </div>
        </div>
        <div class="flex justify-between px-3 my-5">
            <router-link class="flex justify-center items-center w-1/2 py-3 mr-1 bg-text2 text-white rounded cursor-pointer" to="/profile">Edit Profile</router-link>
            <button type="button" @click="nextStep(2)" class="flex justify-center items-center w-1/2 py-3 ml-1 bg-brandYellow text-white rounded cursor-pointer">Continue</button>
        </div>
    </div>
    <div v-if="!loading && step === 2">
        <div class="mt-4 px-3">
            <Error v-if="error">
                {{ error }}
            </Error>
            <div class="block font-bold text-text text-xl">Complete Application Form</div>
            <div class="mt-4 text-lg">
                <template v-if="form.form.suppliers_visible">
                    <!-- Supplier Type -->
                    <div class="mt-0 text-text font-bold pb-2">Supplier Type </div>
                    <select id="supplier_type" name="supplier_type" v-model="supplierType" @change="resetSupplier" class="w-full py-3 px-2 border border-border rounded focus:outline-none cursor-pointer">
                        <option :value="null" selected >All Types</option>
                        <!-- <option value=""></option> -->
                        <option :value="type.id" class="cursor-pointer" v-for="(type, typeIndex) in supplierTypes" :key="typeIndex">{{ type.name }}</option>
                    </select>

                    <!-- Supplier -->
                    <div class="mt-4 text-text font-bold required pb-2">Supplier</div>
                    <select id="supplier" name="supplier" v-model="supplier" class="w-full py-3 px-2 border border-border rounded focus:outline-none cursor-pointer">
                        <option :value="null" disabled selected hidden class="text-gray-400">Select</option>
                        <option :value="supplier.id" class="cursor-pointer" v-for="(supplier, supplierIndex) in filteredSuppliers" :key="supplierIndex">{{ supplier.name }}</option>
                        <option value="other">Other</option>
                    </select>

                    <!-- Other -->
                    <div class="mt-4 text-text font-bold pb-2" v-if="supplier === 'other'">Other</div>
                    <input type="text" name="other" id="other" placeholder="Enter Supplier Name" v-model="other" v-if="supplier === 'other'" class="w-full py-3 px-2 border border-border rounded focus:outline-none">
                </template>

                <!-- Amount -->
                <template v-if="form.form.amount_visible">
                    <div class="mt-4 text-text font-bold required pb-2">Amount</div>
                    <input type="number" name="amount" id="amount" placeholder="Amount" v-model="amount" class="w-full py-3 px-2 border border-border rounded focus:outline-none">
                </template>
                <div v-for="(element, index) in form.form.elements.filter(e => !e['admin'])" :key="index" class="mt-4">
                    <div class="text-text font-bold pb-2" :class="{'required': element.required}" v-if="element.type !== 'heading'">{{ element.label }}</div>
                    <template v-if="element.type === 'textInput'">
                        <input type="text" v-if="element.mask && element.mask.visible" v-model="element.value" v-maska="element.mask.value" :placeholder="element.placeholder.visible ? element.placeholder.value : ''" class="w-full py-3 px-2 border border-border rounded focus:outline-none">
                        <input type="text" v-else v-model="element.value" :placeholder="element.placeholder.visible ? element.placeholder.value : ''" class="w-full py-3 px-2 border border-border rounded focus:outline-none">
                    </template>
                    <template v-if="element.type === 'longTextInput'">
                        <textarea class="w-full p-2 rounded border border-border focus:outline-none" rows="6" v-model="element.value" :placeholder="element.placeholder.visible ? element.placeholder.value : ''"></textarea>
                    </template>
                    <template v-if="element.type === 'numberInput'">
                        <input type="number" v-model="element.value" :placeholder="element.placeholder.visible ? element.placeholder.value : ''" class="w-full py-3 px-2 border border-border rounded focus:outline-none">
                    </template>
                    <template v-if="element.type === 'selectInput'">
                        <select :id="element.id" :name="element.id" v-model="element.value" class="w-full py-3 px-2 border border-border rounded focus:outline-none cursor-pointer">
                            <option value="" v-if="element.placeholder.visible" disabled selected hidden>{{ element.placeholder.value }}</option>
                            <option :value="option.value ? option.value : option.label" class="cursor-pointer" v-for="(option, selectIndex) in element.options" :key="selectIndex">{{ option.label }}</option>
                        </select>
                    </template>
                    <template v-if="element.type === 'radioInput'">
                        <div class="text-text font-bold text-base">
                            <div class="flex items-center mb-4" v-for="(option, optionIndex) in element.options" :key="optionIndex">
                                <input type="radio" :id="option.id" :name="option.id" v-model="element.value" :value="option.value ? option.value : option.label">
                                <label :for="option.id">{{ option.label }}</label>
                            </div>
                        </div>
                    </template>
                    <template v-if="element.type === 'checkboxInput'">
                        <div class="text-text font-bold text-base">
                            <div class="flex items-center mb-4" v-for="(option, optionIndex) in element.options" :key="optionIndex">
                                <input type="checkbox" :id="option.id" :name="option.id" v-model="element.value" :value="option.value ? option.value : option.label">
                                <label :for="option.id">{{ option.label }}</label>
                            </div>
                        </div>
                    </template>
                    <template v-if="element.type === 'fileUpload'">
                        <span v-for="(file, fileIndex) in element.value" class="text-base mb-1 block flex flex-row items-center my-2" :key="fileIndex">
                            <a :href="S3URL + '/' + file.path " target="_blank" class="text-gray-800 hover:text-default-500 truncate">{{ file.filename }}</a>
                            <button type="button" @click="removeFile(element, fileIndex)">
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" class="svg-inline--fa fa-times w-6 h-6 text-red-700 ml-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path>
                                </svg>
                            </button>
                        </span>
                        <file-upload @uploaded="fileUploaded($event, element)" accept="image/*"></file-upload>
                    </template>
                    <template v-if="element.type === 'dateInput'">
                        <input type="date" v-model="element.value" :placeholder="element.placeholder.visible ? element.placeholder.value : ''" class="w-full py-3 px-2 border border-border rounded focus:outline-none">
                    </template>
                    <template v-if="element.type === 'heading'">
                        <div class="block pt-4 font-bold">{{ element.label }}</div>
                    </template>
                    <template v-if="element.help?.visible && element.help?.value">
                        <span class="text-sm text-gray-500">{{ element.help.value }}</span>
                    </template>
                </div>

                <div class="flex flex-col border-t border-border mt-5 pt-5">
                    <Error v-if="!valid">{{ errorMessage ? errorMessage : 'Please complete all required fields' }}</Error>
                    <div class="flex justify-between pb-2">
                        <a @click="save" class="flex justify-center items-center w-1/2 py-3 px-2 mr-1 bg-text2 text-white rounded cursor-pointer whitespace-normal text-center">Finish Later</a>
                        <button type="button" :disabled="!valid" @click="submit" class="flex justify-center items-center w-1/2 py-3 px-2 ml-1 bg-brandYellow text-white rounded cursor-pointer focus:outline-none whitespace-normal" :class="{'opacity-25': !valid}">
                            Submit Form
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!loading && step === 3">
        <!-- Thank You -->
        <div class="mt-6 mx-3 font-bold text-text text-xl" v-if="submission.lodged_at">
            Thank you for submitting the {{ form.name }} Application.
        </div>
        <div class="mt-6 mx-3 font-bold text-text text-xl" v-else>
            Your {{ form.name }} Application has been saved for completion at a later time.
        </div>

        <!-- Complete Application Form-->
        <div class="mt-6 px-3" v-if="form.steps && form.steps.step1 && submission.lodged_at">
            <div class="font-bold text-text text-xl">What happens next?</div>
            <div class="mt-4 font-bold text-primary text-lg" v-if="form.steps.step1">
                1. {{ form.steps.step1 }}
            </div>
            <div class="mt-4 font-bold text-primary text-lg" v-if="form.steps.step2">
                2. {{ form.steps.step2 }}
            </div>
            <div class="mt-4 font-bold text-primary text-lg" v-if="form.steps.step3">
                3. {{ form.steps.step3 }}
            </div>
            <div class="mt-4 font-bold text-primary text-lg" v-if="form.steps.step4">
                4. {{ form.steps.step4 }}
            </div>
            <div class="mt-4 font-bold text-primary text-lg" v-if="form.steps.step5">
                5. {{ form.steps.step5 }}
            </div>
        </div>
        <div class="flex justify-between px-3 mt-6 items-center">
            <router-link to="/dashboard" class="block px-4 py-3 bg-brandYellow text-white rounded w-full text-center">Back to Dashboard</router-link>
        </div>
    </div>
</template>

<script>

import Header from '@/views/partials/Header'
import { ref, onBeforeMount, computed } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import FileUpload from '../../components/FileUpload'
import Error from '@/components/Error'
import dayjs from 'dayjs'

export default {
    name: 'NewApplicationForm',
    components: { FileUpload, Header, Error },
    props: {
        formID: { required: false },
        memberID: { required: false },
        submissionID: { required: false }
    },
    setup (props) {
        const store = useStore()
        const loading = ref(true)
        const step = ref(1)
        const form = ref({})
        const member = ref({})
        const applicant = ref('')
        const s3URL = process.env.VUE_APP_S3_URL
        const supplierTypes = ref([])
        const suppliers = ref([])
        const supplierType = ref(null)
        const supplier = ref(null)
        const other = ref(null)
        const urgent = ref(false)
        const amount = ref(null)
        const submission = ref({})
        const showFAQs = ref(false)
        const error = ref(null)
        const errorMessage = ref(null)
        const submitting = ref(false)

        function changeMember () {
            loading.value = true

            axios.get(process.env.VUE_APP_API_URL + '/public/form', {
                params: {
                    member: applicant.value,
                    form: form.value.id
                }
            }).then(response => {
                if (response.data.success) {
                    member.value = response.data.member
                    form.value = response.data.form
                    supplierTypes.value = response.data.supplier_types
                    suppliers.value = response.data.suppliers
                    loading.value = false
                }
            })
        }

        function fileUploaded (payload, element) {
            if (!element.value || element.value === '') {
                element.value = payload.data.map(file => {
                    return {
                        filename: file.filename,
                        path: file.path
                    }
                })
            } else {
                element.value = element.value.concat(payload.data.map(file => {
                    return {
                        filename: file.filename,
                        path: file.path
                    }
                }))
            }
        }

        const valid = computed(() => {
            let valid = true

            form.value.form.elements.forEach(element => {
                if (element.required && (!element.value || element.value === '' || !element.value.length) && !element.admin) {
                    valid = false
                }
            })

            if (form.value.form.amount_visible && amount.value && amount.value > form.value.values.remaining) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                errorMessage.value = `The amount requested ($${amount.value}) is greater than the remaining amount ($${form.value.values.remaining})`
                valid = false
            }

            if (form.value.form.suppliers_visible && !supplier.value) {
                valid = false
            }

            if (form.value.form.amount_visible && !amount.value) {
                valid = false
            }

            return valid
        })

        function submit () {
            if (submitting.value === true) {
                return
            }

            errorMessage.value = null
            submitting.value = true

            if (!valid.value) {
                submitting.value = false
                return
            }

            error.value = null

            let _url = ''

            if (props.submissionID) {
                _url = process.env.VUE_APP_API_URL + '/public/submit-form/' + props.submissionID
            } else {
                _url = process.env.VUE_APP_API_URL + '/public/submit-form'
            }
            axios.post(_url, {
                form: form.value.id,
                submission: form.value.form,
                member: member.value.id,
                supplierType: supplierType.value,
                supplier: supplier.value,
                other: other.value,
                urgent: urgent.value,
                amount: amount.value
            }).then(response => {
                if (!response.data.success) {
                    error.value = response.data.message
                    return window.scrollTo(0, 0)
                }

                submission.value = response.data.data
                store.dispatch('GET_FORMS', member.value.id)
                nextStep(3)
            }).finally(() => {
                submitting.value = false
            })
        }

        function save () {
            let _url = ''

            if (props.submissionID) {
                _url = process.env.VUE_APP_API_URL + '/public/submit-form/' + props.submissionID
            } else {
                _url = process.env.VUE_APP_API_URL + '/public/submit-form'
            }

            axios.post(_url, {
                form: form.value.id,
                submission: form.value.form,
                member: member.value.id,
                supplierType: supplierType.value,
                supplier: supplier.value,
                other: other.value,
                urgent: urgent.value,
                amount: amount.value,
                partial: true
            }).then(response => {
                submission.value = response.data.data
                nextStep(3)
            })
        }

        const filteredSuppliers = computed(() => {
            if (supplierType.value) {
                return suppliers.value.filter(supplier => {
                    return supplier.types.indexOf(supplierType.value) !== -1
                })
            } else {
                return suppliers.value
            }
        })

        function resetSupplier () {
            supplier.value = null
        }

        onBeforeMount(() => {
            applicant.value = props.memberID

            axios.get(process.env.VUE_APP_API_URL + '/public/form', {
                params: {
                    member: props.memberID,
                    form: props.formID
                }
            }).then(response => {
                if (response.data.success) {
                    member.value = response.data.member
                    form.value = response.data.form
                    supplierTypes.value = response.data.supplier_types
                    suppliers.value = response.data.suppliers
                }

                if (props.submissionID) {
                    axios.get(process.env.VUE_APP_API_URL + '/public/submission', {
                        params: {
                            submission: props.submissionID
                        }
                    }).then(response => {
                        member.value = response.data.member
                        form.value.form.elements = response.data.submission.elements
                        amount.value = response.data.amount
                        supplierType.value = response.data.supplier_type_id
                        supplier.value = response.data.supplier_id
                        loading.value = false
                    })
                } else {
                    loading.value = false
                }
            })
        })

        function nextStep (number) {
            step.value = number
            window.scrollTo(0, 0)
        }

        function removeFile (element, index) {
            element.value.splice(index, 1)
        }

        return {
            loading,
            step,
            form,
            member,
            s3URL,
            store,
            applicant,
            changeMember,
            fileUploaded,
            submit,
            save,
            valid,
            filteredSuppliers,
            supplierTypes,
            supplierType,
            supplier,
            resetSupplier,
            other,
            urgent,
            amount,
            nextStep,
            submission,
            suppliers,
            dayjs,
            showFAQs,
            removeFile,
            error,
            errorMessage
        }
    }
}
</script>

<style scoped>

</style>
