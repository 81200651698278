<template>
    <Header></Header>
    <div class="px-3 mt-4">
        <div class="mt-3 text-lg text-brandBlue">Member: {{ store.getters.user.first + ' ' + store.getters.user.last }}</div>
        <div class="mt-3 text-lg text-brandBlue" v-if="spouse">Spouse: {{ spouse.first + ' ' + spouse.last }}</div>
        <div class="mt-3 text-lg text-brandBlue" v-if="dependents && dependents.length > 0">Dependents: {{ dependents.filter(item => item.active === true).map(children => { return children.first }).join(', ') }}</div>
    </div>

    <!-- NEW APPLICATION -->
    <ApplicationFormsList :show-applicants="true"></ApplicationFormsList>
</template>

<script>
import Header from '@/views/partials/Header'
import ApplicationFormsList from '@/views/partials/ApplicationFormsList'
import { useStore } from 'vuex'
import { computed, onBeforeMount, ref } from 'vue'

export default {
    name: 'ApplicationForms',
    components: {
        Header,
        ApplicationFormsList
    },
    setup () {
        const store = useStore()
        const forms = ref([])
        const s3URL = process.env.VUE_APP_S3_URL

        const spouse = computed(() => {
            if (store.getters.user && store.getters.user.relationships) {
                return store.getters.user.relationships.find(relationship => {
                    return relationship.type.name === 'Spouse'
                })
            } else {
                return null
            }
        })

        const dependents = computed(() => {
            if (store.getters.user && store.getters.user.relationships) {
                return store.getters.user.relationships.filter(relationship => {
                    return relationship.type.name === 'Dependent'
                })
            } else {
                return null
            }
        })

        onBeforeMount(() => {
            forms.value = store.getters.forms

            store.dispatch('GET_ME')
            store.dispatch('GET_FORMS').then(() => {
                forms.value = store.getters.forms
            })
        })

        return {
            store,
            spouse,
            dependents,
            forms,
            s3URL
        }
    }
}
</script>

<style scoped>

</style>
