<template>
    <div class="mt-4 px-3">
        <div class="block text-gray-600 text-xl">New Application</div>
        <form action="" class="mt-3" v-if="showApplicantsSelect && store.getters.user.relationships && store.getters.user.relationships.length > 0">
            <select id="applicant" name="applicant" v-model="applicant" @change="updateForms" class="w-full py-3 px-4 border border-border rounded focus:outline-none cursor-pointer">
                <option value="">Select a Member, Spouse or Dependant</option>
                <option :value="store.getters.user.id" class="cursor-pointer">{{ store.getters.user.first + ' ' + store.getters.user.last }}</option>
                <option v-for="(applicant, index) in store.getters.user.relationships.filter(application => application.active === true)" :key="index" :value="applicant.id" class="cursor-pointer">{{ applicant.first + ' ' + applicant.last }}</option>
            </select>
        </form>
        <div class="mb-6 text-gray-600" v-if="applicant !== ''">Select application below to start</div>
        <div class="flex w-full items-center justify-center" v-if="loading">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="spinner" class="svg-inline--fa fa-spinner fa-w-16 fa-spin w-16 h-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z"></path></svg>
        </div>
        <div class="grid grid-cols-2 gap-2" v-if="!loading && applicant !== ''">
            <div class="w-full px-2 bg-healthcare text-white" :style="'background-color: ' + form.background_colour + ';'" v-for="(form, index) in forms" :key="index">
                <div class="h-20 flex ">
                    <img class="mx-auto h-20" :src="s3URL + '/' + form.icon_url" alt="">
                </div>
                <h3 class="mt-3 text-center text-md min-h-24">{{ form.name }}</h3>
                <div class="flex justify-between flex-wrap mt-2">
                    <h6>Limit</h6>
                    <h6 v-if="form.limit > 0">${{ form.limit.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</h6>
                    <h6 v-else>N/A</h6>
                </div>
                <div class="flex justify-between flex-wrap mt-3">
                    <h6>Remaining</h6>
                    <h6 v-if="form.limit > 0">${{ form.values.remaining.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</h6>
                    <h6 v-else>N/A</h6>
                </div>
                <div class="flex justify-between py-3 items-center mt-3">
                    <h6 id="healthcareFaqOpen" class="cursor-pointer" @click="openFAQs(form, $event)" v-if="form.faqs && form.faqs.length > 0">FAQ'S</h6>
                    <router-link :to="'/applications/new/' + form.id + '/' + applicant"
                        class="flex w-12 h-12 justify-center items-center bg-white rounded-full border-2 border-white cursor-pointer text-gray-600"
                        v-if="(form.values.remaining > 0 || form.limit === 0) && (!form.remaining_threshold || form.values.remaining >= form.remaining_threshold)"
                    >
                        GO
                    </router-link>
                </div>
                <div class="transition-all duration-300 ease-in-out absolute left-0 right-0 bg-white p-2 h-full max-w-screen-mobile" v-if="showFAQs && showFAQs.id === form.id">
                    <div class="flex justify-between items-center">
                        <div class="font-bold text-black text-2xl">FAQ's</div>
                        <div id="healthcareFaqClose" class="w-32 p-3 bg-secondary text-center text-white font-bold rounded-sm cursor-pointer" @click="showFAQs = null">CLOSE</div>
                    </div>
                    <!-- Questions -->
                    <div class="pb-4">
                        <div class="mt-4 text-black" v-for="(faq, FAQIndex) in showFAQs.faqs" :key="FAQIndex">
                            <div class="font-bold">Q: {{ faq.question }} </div>
                            <div class="mt-1 font-normal">A: {{ faq.answer }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, onBeforeMount } from 'vue'

export default {
    name: 'ApplicationFormsList',
    props: {
        showApplicants: {
            default: false
        }
    },
    setup (props) {
        const store = useStore()
        const forms = ref([])
        const applicant = ref('')
        const showApplicantsSelect = ref()
        const s3URL = process.env.VUE_APP_S3_URL
        const loading = ref(false)
        const showFAQs = ref(null)

        onBeforeMount(() => {
            showApplicantsSelect.value = props.showApplicants

            if (store.getters.user.relationships && store.getters.user.relationships.length > 0) {
                applicant.value = ''
            } else {
                applicant.value = store.getters.user.id
            }

            forms.value = store.getters.forms

            store.dispatch('GET_ME')
            store.dispatch('GET_FORMS').then(() => {
                forms.value = store.getters.forms
            })
        })

        function updateForms () {
            loading.value = true

            store.dispatch('GET_FORMS', applicant.value).then(() => {
                forms.value = store.getters.forms
                loading.value = false
            })
        }

        function openFAQs (form, event) {
            showFAQs.value = form
            setTimeout(() => {
                console.log(event.target.parentElement)
                event.target.parentElement.scrollIntoView()
            }, 100)
        }

        return {
            store,
            forms,
            s3URL,
            applicant,
            updateForms,
            showApplicantsSelect,
            loading,
            showFAQs,
            openFAQs
        }
    }
}
</script>

<style scoped>

</style>
