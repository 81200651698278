<template>
    <div class="bg-red-200 border border-red-500 w-full py-3 px-2 text-red-500 rounded text-base text-center mb-2">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Error'
}
</script>
