<template>
    <!-- Header -->
    <div id="header" class="relative w-full overflow-hidden">
        <div class="flex px-3 items-center">
            <div class="w-3/4 pr-10">
                <img src="/img/custodian-community-logo.png" class="w-full" alt="Logo">
            </div>
            <div class="w-1/4">
                <div id="menuIcon" class="p-2 bg-brandYellow text-center text-white rounded-lg cursor-pointer" @click="toggleMenu()" v-if="props.showMenu">Menu</div>
            </div>
        </div>
        <div class="px-3">
            <div class="mt-5 text-gray-600 font-thin text-lg" v-if="props.showDetails">LOGGED IN: {{ store.getters.user.first }} {{ store.getters.user.last }}</div>
            <div class="mt-2 text-text2 text-brandBlue text" v-if="props.showDetails">{{ dayjs().format('dddd MMMM D YYYY') }}</div>
        </div>
        <!-- Menu Slider -->
        <aside class=" transform top-0 right-0 w-full bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30" :class="store.getters.isMenuOpen ? 'translate-x-0' : '-translate-x-full'" style="top: 6rem;">
            <div class="border-text p-4 max-w-screen-mobile mx-auto">
                <div class="flex justify-between items-center text-text">
                    <button type="button" class="block cursor-pointer text-gray-600" @click="logout">LOG OUT</button>
                    <div id="menuSliderClose" class="flex items-center cursor-pointer" @click="toggleMenu()">
                        <div class="mr-2 text-gray-600">CLOSE</div>
                        <img src="@/assets/images/icons/cross.svg" alt="" class="h-9">
                    </div>

                </div>
                <div class="">
                    <router-link to="/dashboard" class="block w-full py-6 mt-6 bg-brandLightBlue text-center text-lg text-white rounded">Go to my Dashboard</router-link>
                    <router-link to="/applications/forms" class="block w-full py-6 mt-4 bg-brandLightBlue text-center text-lg text-white rounded">Start New Application</router-link>
                    <router-link to="/applications/submissions" class="block w-full py-6 mt-4 bg-brandLightBlue text-center text-lg text-white rounded">Application Status</router-link>
                    <router-link to="/profile" class="block w-full py-6 mt-4 bg-brandLightBlue text-center text-lg text-white rounded">My Profile</router-link>
                    <router-link to="/news" class="block w-full py-6 mt-4 bg-brandLightBlue text-center text-lg text-white rounded">News</router-link>
                </div>
            </div>
        </aside>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'

export default {
    name: 'Header',
    props: {
        showMenu: { default: true },
        showDetails: { default: false }
    },
    setup (props) {
        const store = useStore()
        const router = useRouter()

        function logout () {
            store.dispatch('AUTH_LOGOUT').then(() => {
                router.push('/login')
            })
        }

        function toggleMenu () {
            store.dispatch('TOGGLE_MENU')
        }

        return {
            store,
            logout,
            toggleMenu,
            dayjs,
            props
        }
    }
}
</script>

<style scoped>

</style>
