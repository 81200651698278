<template>
    <div class="fixed inset-0 z-50 flex justify-center items-center" v-if="loading">
        <div class="absolute inset-0 bg-black opacity-75"></div>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" class="svg-inline--fa fa-spinner fa-w-16 fa-spin text-white h-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg>
    </div>
    <Header :show-details="true"></Header>
    <!-- Change Member -->
    <template v-if="!selectedSubmission">
        <form action="" class="mt-3 mx-2" v-if="store.getters.user.relationships && store.getters.user.relationships.length > 0">
            <select id="applicant" name="applicant" v-model="applicant" @change="updateForms" class="w-full py-3 px-4 border border-border rounded focus:outline-none cursor-pointer">
                <option :value="store.getters.user.id" class="cursor-pointer">{{ store.getters.user.first + ' ' + store.getters.user.last }}</option>
                <option v-for="(applicant, index) in store.getters.user.relationships" :key="index" :value="applicant.id" class="cursor-pointer">{{ applicant.first + ' ' + applicant.last }}</option>
            </select>
        </form>

        <!-- Application List and Status -->
        <div class="mx-3 rounded-lg px-3 py-4 mt-4 bg-brandBlue font-bold text-white text-lg">
            <h6>Application List and Status</h6>
            <div class="flex flex-wrap justify-between mt-5">
                <h6 >Filter by: </h6>
                <div>
                    <div class="flex items-center">
                        <input type="radio" name="application_type" id="application_type_all" v-model="status" value="all">
                        <label for="application_type_all" class="">All</label>
                    </div>
                </div>
                <div>
                    <div class="flex items-center">
                        <input type="radio" v-model="status" value="pending" name="application_type" id="application_type_pending">
                        <label for="application_type_pending">Pending</label>
                    </div>
                    <div class="mt-3 flex items-center">
                        <input type="radio" v-model="status" value="approved" name="application_type" id="application_type_approved">
                        <label for="application_type_approved">Approved</label>
                    </div>
                    <div class="mt-3 flex items-center">
                        <input type="radio" v-model="status" value="incomplete" name="application_type" id="application_type_incomplete">
                        <label for="application_type_incomplete">Incomplete</label>
                    </div>
                    <div class="mt-3 flex items-center">
                        <input type="radio" v-model="status" value="completed" name="application_type" id="application_type_completed">
                        <label for="application_type_completed">Completed</label>
                    </div>
                    <div class="mt-3 flex items-center">
                        <input type="radio" v-model="status" value="unsuccessful" name="application_type" id="application_type_unsuccessful">
                        <label for="application_type_unsuccessful">Unsuccessful</label>
                    </div>
                </div>
            </div>
            <!-- Application Type -->
            <form action="" class="mt-5 mb-4 text-text">
                <select id="application_type" name="application_type" v-model="form" class="w-full py-3 px-4 border border-border rounded focus:outline-none cursor-pointer">
                    <option value="">All Applications</option>
                    <option v-for="(form, index) in forms" :value="form.id" class="cursor-pointer" :key="index">{{ form.name }}</option>
                </select>
            </form>
        </div>
        <!-- Application Type and Status-->
        <div class="w-full py-6 px-3 flex justify-between text-gray-600" :class="{'bg-background2': index % 2}" v-for="(submission, index) in filteredSubmissions" :key="index">
            <div>
                <h6 v-if="submission.lodged_at">{{ submission.lodged_at }}</h6>
                <h6 v-else>Not Submitted</h6>
                <h6 class="mt-2">{{ submission.friendly_id_display }}</h6>
                <h6 class="mt-2">{{ submission.application_form.name }}</h6>
                <h6 class="mt-2">Status: <span class="text-brandYellow">{{ submission.application_status.name }}</span></h6>
            </div>
            <div>
                <a href="javascript:void(0);" @click="selectSubmission(submission)">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="32" viewBox="0 0 30 32">
                        <defs>
                            <clipPath id="clip-path">
                                <rect width="30" height="32" fill="none"/>
                            </clipPath>
                        </defs>
                        <g id="Group_18" data-name="Group 18" transform="translate(-310 -325)">
                            <g id="Component_62_14" data-name="Component 62 – 14" transform="translate(310 325)" clip-path="url(#clip-path)">
                                <rect id="Rectangle_176" data-name="Rectangle 176" width="30" height="30" fill="none"/>
                                <path id="Path_99" data-name="Path 99" d="M29.9,29.091,23.694,22.47a14.348,14.348,0,0,0,2.633-8.426C26.327,6.219,20.5,0,13.164,0S0,6.219,0,14.044,5.83,28.088,13.164,28.088a12.357,12.357,0,0,0,7.9-2.809L27.267,31.9ZM3.761,14.044c0-5.618,4.137-10.031,9.4-10.031s9.4,4.414,9.4,10.031-4.137,10.031-9.4,10.031S3.761,19.662,3.761,14.044Z" fill="#f6ad00"/>
                            </g>
                        </g>
                    </svg>
                </a>
            </div>
        </div>
        <div class="flex justify-between px-3 items-center mt-6">
            <router-link to="/dashboard" class="block px-4 py-3 bg-brandYellow text-white rounded w-full text-center">Back to Dashboard</router-link>
        </div>
    </template>
    <!-- Application View -->
    <template v-if="selectedSubmission">
        <div class="w-full flex justify-between px-3 py-4 mt-1 bg-brandBlue font-bold text-white text-lg">
            <div>
                <h6>{{ selectedSubmission.friendly_id_display }}</h6>
                <h6 class="mt-2">{{ selectedSubmission.application_form.name }}</h6>
                <h6 class="font-bold mt-2" v-if="selectedSubmission.lodged_at">Application Submitted: {{ dayjs(selectedSubmission.lodged_at).format('D/M/YYYY') }}</h6>
                <h6 class="font-bold mt-2" v-else>Not Submitted</h6>
                <h6 class="mt-2">Status: <span class="font-bold">{{ selectedSubmission.application_status.name }}</span></h6>
            </div>
            <div class="h-20 flex">
                <img class="mx-auto h-20" :src="s3URL + '/' + selectedSubmission.application_form.icon_url" alt="">
            </div>
        </div>

        <!-- Application Info -->
        <div class="px-3">
            <div class="mt-4 text-text font-bold block">Supplier</div>
            <div class="mt-2 text-text" v-if="selectedSubmission.supplier_other">{{ selectedSubmission.supplier_other }}</div>
            <div class="mt-2 text-text" v-else-if="selectedSubmission.supplier">{{ selectedSubmission.supplier.name }}</div>
            <div class="mt-2 text-text" v-else>N/A</div>

            <div class="mt-4 text-text font-bold block">Amount</div>
            <div class="mt-2 text-text" v-if="selectedSubmission.amount && selectedSubmission.amount > 0">${{ selectedSubmission.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</div>
            <div class="mt-2 text-text" v-else>N/A</div>

            <div v-for="(element, index) in selectedSubmission.submission.elements.filter(e => !e.admin)" :key="index">
                <div class="mt-4 text-text font-bold block">{{ element.label }}</div>
                <template v-if="element.type === 'fileUpload'">
                    <span class="mt-2 block" v-if="element.value && element.value.path">
                        <a :href="s3URL+'/'+element.value.path" target="_blank" class="text-gray-800 hover:text-default-500 text-text mt-2">{{ element.value.filename }}</a>
                    </span>
                </template>
                <template v-else-if="element.type === 'checkboxInput'">
                    <div class="mt-2 text-text block">{{ Array.isArray(element.value) ? element.value.join(', ') : element.value }}</div>
                </template>
                <template v-else>
                    <div class="mt-2 text-text block">{{ element.value }}</div>
                </template>
            </div>
        </div>
        <div class="flex justify-between px-3 items-center mt-6" v-if="selectedSubmission.application_status.name === 'Incomplete'">
            <router-link :to="`/applications/edit/${selectedSubmission.application_form_id}/${selectedSubmission.member_id}/${selectedSubmission.id}`" type="button" class="block px-4 py-3 bg-secondary text-white font-bold rounded w-full text-center">CONTINUE</router-link>
        </div>
        <div class="flex justify-between px-3 items-center mt-6">
            <button type="button" class="block px-4 py-3 bg-brandYellow text-white rounded w-full text-center" @click="closeSubmission">Close</button>
        </div>
    </template>
</template>

<script>
import axios from 'axios'
import Header from '@/views/partials/Header'
import { useStore } from 'vuex'
import { onBeforeMount, ref, computed, onMounted } from 'vue'
import dayjs from 'dayjs'
import router from '@/router'

export default {
    name: 'Submissions',
    components: {
        Header
    },
    setup () {
        const store = useStore()

        const applicant = ref(null)
        const loading = ref(true)
        const submissions = ref([])
        const forms = ref([])
        const status = ref('all')
        const form = ref('')
        const selectedSubmission = ref(null)
        const s3URL = process.env.VUE_APP_S3_URL

        onBeforeMount(() => {
            applicant.value = store.getters.user.id
            updateForms()
        })

        onMounted(() => {
            const params = new URLSearchParams(window.location.search)

            if (params.has('status')) {
                status.value = params.get('status')
            }
        })

        function updateForms () {
            loading.value = true

            axios.get(process.env.VUE_APP_API_URL + '/public/submissions', {
                params: {
                    member: applicant.value
                }
            }).then(response => {
                form.value = ''
                status.value = status.value ?? 'all'
                submissions.value = response.data.submissions
                forms.value = response.data.forms
                loading.value = false
            })
        }

        const filteredSubmissions = computed(() => {
            return submissions.value.filter(submission => {
                if (submission.application_status.name.toUpperCase() === 'CANCELLED') {
                    return false
                } else {
                    if (status.value !== 'all') {
                        return status.value.toUpperCase() === submission.application_status.name.toUpperCase()
                    } else {
                        return true
                    }
                }
            }).filter(submission => {
                if (form.value !== '') {
                    return form.value === submission.application_form.id
                } else {
                    return true
                }
            })
        })

        function selectSubmission (submission) {
            loading.value = true

            axios.get(process.env.VUE_APP_API_URL + '/public/submission', {
                params: {
                    submission: submission.id
                }
            }).then(response => {
                selectedSubmission.value = response.data
                console.log(selectedSubmission.value)
                if (selectedSubmission.value.application_status.name === 'Incomplete') {
                    router.push(`/applications/edit/${selectedSubmission.value.application_form_id}/${selectedSubmission.value.member_id}/${selectedSubmission.value.id}`)
                }
                loading.value = false
                window.scrollTo(0, 0)
            })
        }

        function closeSubmission () {
            selectedSubmission.value = null
            window.scrollTo(0, 0)
        }

        return {
            store,
            applicant,
            updateForms,
            status,
            submissions,
            forms,
            form,
            filteredSubmissions,
            loading,
            selectSubmission,
            selectedSubmission,
            s3URL,
            dayjs,
            closeSubmission
        }
    }
}
</script>

<style scoped>

</style>
